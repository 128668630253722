.hop-Text {
    /** Component Tokens */
    --hop-Text-xs-font-size: var(--hop-body-xs-font-size);
    --hop-Text-xs-font-family: var(--hop-body-xs-font-family);
    --hop-Text-xs-font-weight: var(--hop-body-xs-font-weight);
    --hop-Text-xs-line-height: var(--hop-body-xs-line-height);
    --hop-Text-sm-font-size: var(--hop-body-sm-font-size);
    --hop-Text-sm-font-family: var(--hop-body-sm-font-family);
    --hop-Text-sm-font-weight: var(--hop-body-sm-font-weight);
    --hop-Text-sm-line-height: var(--hop-body-sm-line-height);
    --hop-Text-md-font-size: var(--hop-body-md-font-size);
    --hop-Text-md-font-family: var(--hop-body-md-font-family);
    --hop-Text-md-font-weight: var(--hop-body-md-font-weight);
    --hop-Text-md-line-height: var(--hop-body-md-line-height);
    --hop-Text-lg-font-size: var(--hop-body-lg-font-size);
    --hop-Text-lg-font-family: var(--hop-body-lg-font-family);
    --hop-Text-lg-font-weight: var(--hop-body-lg-font-weight);
    --hop-Text-lg-line-height: var(--hop-body-lg-line-height);
    --hop-Text-xl-font-size: var(--hop-body-xl-font-size);
    --hop-Text-xl-font-family: var(--hop-body-xl-font-family);
    --hop-Text-xl-font-weight: var(--hop-body-xl-font-weight);
    --hop-Text-xl-line-height: var(--hop-body-xl-line-height);
    --hop-Text-2xl-font-size: var(--hop-body-2xl-font-size);
    --hop-Text-2xl-font-family: var(--hop-body-2xl-font-family);
    --hop-Text-2xl-font-weight: var(--hop-body-2xl-font-weight);
    --hop-Text-2xl-line-height: var(--hop-body-2xl-line-height);
    --hop-Text-inherit-size-font-size: inherit;
    --hop-Text-inherit-size-font-family: inherit;
    --hop-Text-inherit-size-font-weight: inherit;
    --hop-Text-inherit-size-line-height: inherit;
}

:where(.hop-Text) {
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
}

:where(.hop-Text--xs) {
    --font-size: var(--hop-Text-xs-font-size);
    --font-family: var(--hop-Text-xs-font-family);
    --font-weight: var(--hop-Text-xs-font-weight);
    --line-height: var(--hop-Text-xs-line-height);
}

:where(.hop-Text--sm) {
    --font-size: var(--hop-Text-sm-font-size);
    --font-family: var(--hop-Text-sm-font-family);
    --font-weight: var(--hop-Text-sm-font-weight);
    --line-height: var(--hop-Text-sm-line-height);
}

:where(.hop-Text--md) {
    --font-size: var(--hop-Text-md-font-size);
    --font-family: var(--hop-Text-md-font-family);
    --font-weight: var(--hop-Text-md-font-weight);
    --line-height: var(--hop-Text-md-line-height);
}

:where(.hop-Text--lg) {
    --font-size: var(--hop-Text-lg-font-size);
    --font-family: var(--hop-Text-lg-font-family);
    --font-weight: var(--hop-Text-lg-font-weight);
    --line-height: var(--hop-Text-lg-line-height);
}

:where(.hop-Text--xl) {
    --font-size: var(--hop-Text-xl-font-size);
    --font-family: var(--hop-Text-xl-font-family);
    --font-weight: var(--hop-Text-xl-font-weight);
    --line-height: var(--hop-Text-xl-line-height);
}

:where(.hop-Text--2xl) {
    --font-size: var(--hop-Text-2xl-font-size);
    --font-family: var(--hop-Text-2xl-font-family);
    --font-weight: var(--hop-Text-2xl-font-weight);
    --line-height: var(--hop-Text-2xl-line-height);
}

:where(.hop-Text--inherit) {
    --font-size: var(--hop-Text-inherit-size-font-size);
    --font-family: var(--hop-Text-inherit-size-font-family);
    --font-weight: var(--hop-Text-inherit-size-font-weight);
    --line-height: var(--hop-Text-inherit-size-line-height);
}
